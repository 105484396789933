<script setup>
import { ref, computed } from "vue";
import deviceBreakpoint from "../../mixins/deviceBreakpoint";
// import dateFormatter from "../../services/dates/dateformatter";
import Table from "@/components/table/TableNoExport";
import router from "../../router";

const invoices = ref([
  {
    id: 1,
    number: "Invoice 2525452",
    plan: "Basic Plan",
    amount: 20000,
    status: "Paid",
    invoiceDate: "24th March 2025",
  },
  {
    id: 2,
    number: "Invoice 2525452",
    plan: "Basic Plan",
    amount: 20000,
    status: "Cancelled",
    invoiceDate: "24th March 2025",
  },
  {
    id: 3,
    number: "Invoice 2525452",
    plan: "Basic Plan",
    amount: 20000,
    status: "Paid",
    invoiceDate: "24th March 2025",
  },
  {
    id: 4,
    number: "Invoice 25254523",
    plan: "Basic Plan",
    amount: 20000,
    status: "Paid",
    invoiceDate: "24th March 2025",
  },
  {
    id: 5,
    number: "Invoice 252542352",
    plan: "Basic Plan",
    amount: 20000,
    status: "Cancelled",
    invoiceDate: "24th March 2025",
  },
  {
    id: 6,
    number: "Invoice 252125452",
    plan: "Basic Plan",
    amount: 20000,
    status: "Paid",
    invoiceDate: "24th March 2025",
  },
  {
    id: 7,
    number: "Invoice 252235452",
    plan: "Basic Plan",
    amount: 20000,
    status: "Paid",
    invoiceDate: "24th March 2025",
  },
]);
const billingHistoryHeaders = ref([
  { name: "INVOICE", value: "invoice" },
  { name: "AMOUNT", value: "amount" },
  { name: "STATUS", value: "status" },
  { name: "DATE", value: "invoiceDate" },
  { name: "", value: "action" },
]);
const marked = ref([]);
const searchQuery = ref("");
const { mdAndUp, lgAndUp, xlAndUp } = deviceBreakpoint();
const handleSelectionChange = (val) => {
  marked.value = val;
};
const goBack = () => {
  router.go(-1);
};
// const formatDate = (date) => {
//   return dateFormatter.monthDayYear(date);
// };
const searchBillingHistory = computed(() => {
  if (!searchQuery.value) {
    return invoices.value;
  }
  return invoices.value.filter((invoice) =>
    invoice.number.toString().includes(searchQuery.value)
  );
});
</script>
<template>
  <div
    :class="{ 'container-wide': lgAndUp || xlAndUp }"
    class="container-top h-100"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="text-head font-weight-bold h2 text-black">
          Billing History
        </div>
        <div @click="goBack">
          <span class="s-18 fw-400 cursor-pointer text-black">
            <img src="../../assets/goback.png" alt="" /> Go back</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end mt-3">
        <div class="col-md-4">
          <el-input
            v-model="searchQuery"
            type="text"
            class="w-100"
            placeholder="Search"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <Table
          :data="searchBillingHistory"
          :headers="billingHistoryHeaders"
          :checkMultipleItem="false"
          @checkedrow="handleSelectionChange"
          v-if="searchBillingHistory.length > 0"
        >
          <template v-slot:invoice="{ item }">
            <div class="c-pointer">
              <div class="text-dak s-14 fw-500">{{ item.number }}</div>
              <div class="text-dak s-14 fw-400">{{ item.plan }}</div>
            </div>
          </template>

          <template v-slot:amount="{ item }">
            <div class="c-pointer">NGN{{ item.amount }}</div>
          </template>

          <template v-slot:status="{ item }">
            <div class="c-pointer">
              <span
                class="badge s-14 d-flex justify-content-center fw-400 px-3 py-2"
                :class="{
                  badgeSuccess: item.status === 'Paid',
                  badgeCancelled: item.status === 'Cancelled',
                }"
              >
                {{ item.status }}
              </span>
            </div>
          </template>
          <template v-slot:invoiceDate="{ item }">
            <div class="c-pointer">
              {{ item.invoiceDate }}
            </div>
          </template>
          <template v-slot:action="{ item }">
            {{ item.action }} View invoice
            <!-- <el-dropdown trigger="click">
        <el-icon>
          <MoreFilled />
        </el-icon>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <router-link
                :to="`/tenant/people/addnewconvert?id=${item.id}`"
                class="text-color"
              >
                {{ navigatorLang === "en-US" ? "Edit" : $t("people.edit") }}
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <div
                class="text-decoration-none text-color"
                @click="showConfirmModal(item.id)"
              >
                {{ navigatorLang === "en-US" ? "Delete" : $t("people.delete") }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown> -->
          </template>
        </Table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.badgeSuccess {
  font-size: 14px;
  border-radius: 60px;
  border: 1px solid #24e94f;
  background: #f3ffef;
  width: 72px !important;
  height: 32px !important;
}
.badgeCancelled {
  font-size: 14px;
  border-radius: 60px;
  border: 1px solid #f88787;
  background: #ffdbc9;
  width: 96px !important;
  height: 32px !important;
}
</style>
